// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-4003-jsx": () => import("/opt/build/repo/src/pages/4003.jsx" /* webpackChunkName: "component---src-pages-4003-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("/opt/build/repo/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-atfl-2019-jsx": () => import("/opt/build/repo/src/pages/atfl2019.jsx" /* webpackChunkName: "component---src-pages-atfl-2019-jsx" */),
  "component---src-pages-castle-jsx": () => import("/opt/build/repo/src/pages/castle.jsx" /* webpackChunkName: "component---src-pages-castle-jsx" */),
  "component---src-pages-font-book-x-jsx": () => import("/opt/build/repo/src/pages/font-book-x.jsx" /* webpackChunkName: "component---src-pages-font-book-x-jsx" */),
  "component---src-pages-gattaca-jsx": () => import("/opt/build/repo/src/pages/gattaca.jsx" /* webpackChunkName: "component---src-pages-gattaca-jsx" */),
  "component---src-pages-google-design-exercise-jsx": () => import("/opt/build/repo/src/pages/google-design-exercise.jsx" /* webpackChunkName: "component---src-pages-google-design-exercise-jsx" */),
  "component---src-pages-homelessness-in-toronto-jsx": () => import("/opt/build/repo/src/pages/homelessness-in-toronto.jsx" /* webpackChunkName: "component---src-pages-homelessness-in-toronto-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-konrad-jsx": () => import("/opt/build/repo/src/pages/konrad.jsx" /* webpackChunkName: "component---src-pages-konrad-jsx" */),
  "component---src-pages-test-jsx": () => import("/opt/build/repo/src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-trying-to-be-better-editor-jsx": () => import("/opt/build/repo/src/pages/trying-to-be-better-editor.jsx" /* webpackChunkName: "component---src-pages-trying-to-be-better-editor-jsx" */),
  "component---src-pages-wander-home-jsx": () => import("/opt/build/repo/src/pages/wander-home.jsx" /* webpackChunkName: "component---src-pages-wander-home-jsx" */),
  "component---src-pages-watch-apps-jsx": () => import("/opt/build/repo/src/pages/watch-apps.jsx" /* webpackChunkName: "component---src-pages-watch-apps-jsx" */),
  "component---src-pages-ysdn-2019-teaser-jsx": () => import("/opt/build/repo/src/pages/ysdn2019-teaser.jsx" /* webpackChunkName: "component---src-pages-ysdn-2019-teaser-jsx" */),
  "component---src-pages-ysdn-2019-jsx": () => import("/opt/build/repo/src/pages/ysdn2019.jsx" /* webpackChunkName: "component---src-pages-ysdn-2019-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

